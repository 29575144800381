import React from 'react';
import { Link } from 'gatsby';

import Layout from '@rocketseat/gatsby-theme-docs/src/components/Layout';
import SEO from '@rocketseat/gatsby-theme-docs/src/components/SEO';

export default function NotFound() {
  return (
    <Layout title="Ooops! 🕵">
      <SEO title="404: Not found" />
      <p style={{
        color: "rgb(75, 75, 75)",
        fontSize: "1.25em",
        fontWeight: "600",
      }}>Ei dev, essa página não pôde ser encontrada :/</p>
      <p>
        Se quiser voltar para a página inicial, <Link to="/">é só clicar aqui.</Link>
      </p>
    </Layout>
  );
}
